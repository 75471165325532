<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <QuickTranslation
          translation-key="structure.label"
          tag="h1"
        />
      </v-list-item-content>
    </v-list-item>
    <v-tabs
      centered
      :slider-size="3"
      color="primary"
      item-key="name"
      v-model="tab"
      class="justify-center"
    >
      <v-tab>
        <v-icon left>mdi-view-grid-outline</v-icon>
        {{ $t('structure.titles.details') }}
      </v-tab>
      <v-tab>
        <v-icon left>mdi-group</v-icon>
        {{ $t('structure.titles.details_group') }}
      </v-tab>
      <v-tab-item class="pt-6">
        <v-data-table
          :search="search"
          :headers="headers"
          :items="details"
          class="agTable"
          :loading="loading"
          :no-results-text="$t('common.ui.not_search_found')"
          :no-data-text="$t('common.ui.not_data_found')"
          :loading-text="$t('common.notification.loadData')"
          :footer-props="{
            itemsPerPageText: $t('common.table.itemsPerPageText'),
            pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
          }"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-row class="d-flex justify-space-between align-baseline">
                <v-col class="pl-0" lg="4" md="4" sm="6" xl="4" cols="6">
                  <v-text-field
                    onkeypress="return (event.charCode != 34)"
                    v-on:keyup.86="replaceSearch"
                    v-on:keyup.ctrl.86="replaceSearch"
                    dense
                    filled
                    rounded
                    color="primary"
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('common.titles.filter')"
                    :clearable="true"
                    single-line
                    hide-details
                  />
                </v-col>
                <v-spacer></v-spacer>
                <v-col lg="4" md="4" sm="4" xl="4" cols="4" class="text-end">
                  <v-btn
                    small
                    tile
                    :elevation="0"
                    color="gray"
                    style="border-radius: 1.5em"
                    @click="openCreateDialog"
                  >
                    <v-icon small color="neuter" left>mdi-plus</v-icon>
                    {{ $t('common.buttons.new') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.structure_type="{ item }">
            <v-chip class="ma-1" small>
              {{ translateType(item.structure_type) }}
            </v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <span v-if="!item.structureCode || item.structureCode === '-'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="neuter"
                    class="mr-2"
                    small
                    @click="editItem(item)"
                  >
                    mdi-square-edit-outline
                  </v-icon>
                </template>
                <span>
                  {{ $t('common.buttons.edit') }}
                  <span style="font-size: 12px; color: #aaaaaa">
                    {{ $t('structure.label') }}
                  </span>
                </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="neuter"
                    small
                    @click="deleteItemDialog(item)"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </template>
                <span>
                  {{ $t('common.buttons.delete') }}
                  <span style="font-size: 12px; color: #aaaaaa">
                    {{ $t('structure.label') }}
                  </span>
                </span>
              </v-tooltip>
            </span>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item class="pt-6">
        <v-data-table
          :headers="groupHeaders"
          :search="search_"
          :items="groupDetailsByCompany"
          class="agTable"
          :loading="groupLoading"
          :no-results-text="$t('common.ui.not_search_found')"
          :no-data-text="$t('common.ui.not_data_found')"
          :footer-props="{
            itemsPerPageText: $t('common.table.itemsPerPageText'),
            pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
          }"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-row class="d-flex justify-space-between align-baseline">
                <v-col class="pl-0" lg="4" md="4" sm="6" xl="6" cols="6">
                  <v-text-field
                    onkeypress="return (event.charCode != 34)"
                    v-on:keyup.86="replaceSearch_"
                    v-on:keyup.ctrl.86="replaceSearch_"
                    dense
                    filled
                    rounded
                    color="primary"
                    v-model="search_"
                    append-icon="mdi-magnify"
                    :label="$t('common.titles.filter')"
                    :clearable="true"
                    single-line
                    hide-details
                  />
                </v-col>
                <v-spacer></v-spacer>
                <v-col lg="4" md="4" sm="4" xl="4" cols="4" class="text-end">
                  <v-btn
                    small
                    tile
                    :elevation="0"
                    color="gray"
                    style="border-radius: 1.5em"
                    @click="openCreateGroupDialog"
                  >
                    <v-icon small color="neuter" left>mdi-plus</v-icon>
                    {{ $t('common.buttons.new') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <span v-if="!item.groupCode || item.groupCode === '-'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="neuter"
                    class="mr-2"
                    small
                    @click="groupEditItem(item)"
                  >
                    mdi-square-edit-outline
                  </v-icon>
                </template>
                <span>
                  {{ $t('common.buttons.edit') }}
                  <span style="font-size: 12px; color: #aaaaaa">
                    {{ $t('structure.label') }}
                  </span>
                </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="neuter"
                    small
                    @click="groupDeleteItemDialog(item)"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </template>
                <span>
                  {{ $t('common.buttons.delete') }}
                  <span style="font-size: 12px; color: #aaaaaa">
                    {{ $t('structure.label') }}
                  </span>
                </span>
              </v-tooltip>
            </span>
          </template>
          <template v-slot:item.structure="{ item }">
            <v-chip
              class="ma-1"
              v-for="(details, i) in item.structure"
              small
              :key="i"
              >{{ details.details_name }}</v-chip
            >
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{ title }}</v-card-title>
          <v-card-text class="pa-6">{{ dialogText }}</v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small text color="neuter" @click="dialogClose">
              {{ $t('common.buttons.cancel') }}
            </v-btn>
            <v-btn small elevation="0" color="error" class="t-bw-error--text" @click="deleteItem">
              {{ $t('common.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="groupShowDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{ title }}</v-card-title>
          <v-card-text class="pa-6">{{ dialogText }}</v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small text color="neuter"  @click="groupDialogClose">
              {{ $t('common.buttons.cancel') }}
            </v-btn>
            <v-btn small elevation="0" color="error" class="t-bw-error--text" @click="groupDeleteItem">
              {{ $t('common.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog persistent v-model="dialog" max-width="500px">
        <v-card :light="true" color="white">
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-6">
            <v-container>
              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      dense
                      onkeypress="return (event.charCode != 34)"
                      v-on:keyup.86="replaceDetailsName"
                      v-on:keyup.ctrl.86="replaceDetailsName"
                      color="primary"
                      v-model="editedItem.details_name"
                      required
                      class="required"
                      :rules="requireRules"
                      auto-grow
                    >
                      <template v-slot:label>
                        {{ $t('structure.fields.name') }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      outlined
                      dense
                      :no-data-text="$t('common.fields.noDataAvailable')"
                      clearable
                      color="primary"
                      item-color="primary"
                      :items="orderDatas(structureType, 'text')"
                      :rules="requireRules"
                      class="required"
                      item-value="value"
                      item-text="text"
                      v-model="editedItem.structure_type"
                      small-chips
                    >
                      <template v-slot:label>
                        {{ $t('structure.fields.structure_type') }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="editedItem.structure_type === 'NUMBER'">
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                        type="number"
                        clearable
                        color="primary"
                        :rules="compareMax(0,5)"
                        v-model="editedItem.afterPoint"
                    >
                      <template v-slot:label>
                        {{ $t('structure.fields.afterPoint') }}
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small text color="neuter" @click="close">
              {{ $t('common.buttons.cancel') }}
            </v-btn>
            <v-btn
              small elevation="0" color="primary" class="t-bw-primary--text"
              :disabled="!valid"
              @click="save"
            >
              {{ $t('common.buttons.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog persistent v-model="groupDialog" max-width="500px">
        <v-card :light="true" color="white">
          <v-card-title>
            <span class="headline">{{ groupFormTitle }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-6">
            <v-container>
              <v-form ref="groupForm" v-model="valid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      onkeypress="return (event.charCode != 34)"
                      v-on:keyup.86="replaceGroupDetailsName"
                      v-on:keyup.ctrl.86="replaceGroupDetailsName"
                      color="primary"
                      v-model="groupEditedItem.group_details_name"
                      required
                      class="required"
                      :rules="requireAndMaxRules(max)"
                      :counter="max"
                      maxlength="max"
                    >
                      <template v-slot:label>
                        {{ $t('groupStructure.fields.name') }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      dense
                      outlined
                      :no-data-text="$t('common.fields.noDataAvailable')"
                      ref="detailsFilters"
                      clearable
                      multiple
                      color="primary"
                      item-color="primary"
                      :items="orderDatas(details, 'details_name')"
                      :rules="requireRules"
                      return-object
                      class="required"
                      item-text="details_name"
                      v-model="groupEditedItem.structure"
                      small-chips
                      @mouseup="
                        checkState(
                          'details',
                          $refs.detailsFilters.filteredItems,
                          groupEditedItem.structure
                        )
                      "
                      @click:clear="checkboxState.details = false"
                    >
                      <template v-slot:label>
                        {{ $t('groupStructure.fields.structures') }}
                        <strong class="red--text">*</strong>
                      </template>
                      <template v-slot:prepend-item>
                        <v-checkbox
                          v-model="checkboxState.details"
                          color="primary"
                          class="pt-2 pb-2 mt-0 pl-4"
                          :hide-details="true"
                          @change="
                            groupEditedItem.structure = selectAll(
                              'details',
                              groupEditedItem.structure,
                              $refs.detailsFilters.filteredItems,
                              checkboxState.details
                            )
                          "
                        >
                          <template v-slot:label>
                            <v-btn small color="primary" x-small text>
                              {{ $t('common.titles.selectAll') }}
                            </v-btn>
                          </template>
                        </v-checkbox>
                        <v-divider class="mb-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small text color="neuter"  @click="groupClose">
              {{ $t('common.buttons.cancel') }}
            </v-btn>
            <v-btn
              small elevation="0" color="primary" class="t-bw-primary--text"
              :disabled="!valid"
              @click="groupSave"
            >
              {{ $t('common.buttons.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import i18n from '@/plugins/i18n'
  import { mapActions, mapGetters } from 'vuex'
  import LanguajeService from '../../../services/LanguajeService'
  import sortList from '@/services/OrderListService'
  import _ from "lodash";

  export default {
    data: () => ({
      // Comun
      title: 'Información',
      search: '',
      search_: '',
      dialogText: 'Añada un texto a este dialogo',
      max: 40,
      tab: 0,
      profile: null,
      // Dados Gerais
      dialog: false,
      editedIndex: -1,
      editedItem: {
        details_name: null,
        structure_type: null,
        structure_component: null,
        afterPoint: null,
      },
      checkboxState: {
        details: false,
      },
      defaultItem: {
        details_name: null,
        structure_type: null,
        structure_component: null,
      },
      showDialog: false,
      valid: false,
      deletingItem: {},

      // Groups
      groupDialog: false,
      groupEditedIndex: -1,
      groupEditedItem: {
        group_details_name: null,
        languageKey: null,
        structure: null,
      },
      groupDefaultItem: {
        group_details_name: null,
        languageKey: null,
        structure: null,
      },
      groupShowDialog: false,
      goupValid: false,
      groupDeletingItem: {},
    }),

    computed: {
      ...mapGetters({
        details: 'details/details',
        groupDetailsByCompany: 'groupDetails/groupDetailsByCompany',
        groupLoading: 'groupDetails/loading',
        structureType: 'details/detailsLocalEnum',
        loading: 'details/loading',
        requireAndMaxRules: 'general/requireAndMaxRules',
        compareMax: 'general/compareMax',
        requireRules: 'general/requireRules',
        languageStructure: 'general/languageStructure',
      }),
      groupHeaders: () => [
        {
          text: i18n.t('groupStructure.fields.name'),
          value: 'group_details_name',
        },
        {
          text: i18n.t('groupStructure.fields.structures'),
          value: 'structure',
        },
        {
          text: i18n.t('common.fields.action'),
          value: 'action',
          width: 100,
          sortable: false,
          align: 'center',
        },
      ],
      headers: () => [
        { text: i18n.t('structure.fields.name'), value: 'details_name' },
        {
          text: i18n.t('structure.fields.structure_type'),
          value: 'structure_type',
        },
        {
          text: i18n.t('common.fields.action'),
          value: 'action',
          width: 100,
          sortable: false,
          align: 'center',
        },
      ],
      groupFormTitle() {
        return (
          (this.groupEditedIndex === -1
            ? i18n.t('common.titles.new')
            : i18n.t('common.titles.edit')) +
          ' ' +
          i18n.t('groupStructure.titles.new_group')
        )
      },
      formTitle() {
        return (
          (this.editedIndex === -1
            ? i18n.t('common.titles.new')
            : i18n.t('common.titles.edit')) +
          ' ' +
          i18n.t('structure.titles.new_structure')
        )
      },
    },

    created() {
      this.initialize()
    },

    methods: {
      ...mapActions({
        fetchListStructures: 'details/fetchListStructures',
        fetchGroupStructuresByCompany:
          'groupDetails/fetchGroupStructuresByCompany',
        saveStructures: 'details/saveStructures',
        saveGroupStructures: 'groupDetails/saveGroupStructures',
        deleteStructure: 'details/deleteStructure',
        deleteGroupStructure: 'groupDetails/deleteGroupStructure',
      }),
      orderDatas(list, order) {
        return sortList.orderListByUppercase(list, order)
      },
      initialize() {
        this.profile = JSON.parse(localStorage.getItem('profile'))
        this.fetchListStructures([this.profile, this.$toast])
        this.fetchGroupStructuresByCompany([this.profile, this.$toast])
      },
      selectAll(type, length, value, checkbox) {
        if (checkbox == false) {
          this.$nextTick(function () {
            switch (type) {
              case 'details':
                this.checkboxState.details = false
                break
            }
          })
          return []
        }
        let aux = []
        if (length != null) {
          _.forEach(length, (object) => {
            if (!_.find(value, (o) => {
              return o.id === object.id
            }))
              value.push(object)
          })

          aux = value
        } else {
          aux = value
        }
        return aux
      },
      checkState(prefix, total, values) {
        if (values != null && values.length == total.length) {
          switch (prefix) {
            case 'details':
              this.checkboxState.details = true
              break
          }
        } else {
          switch (prefix) {
            case 'details':
              this.checkboxState.details = false
              break
          }
        }
      },
      groupEditItem(item) {
        this.groupEditedIndex = this.groupDetailsByCompany.indexOf(item)
        this.groupEditedItem = Object.assign({}, item)
        this.groupDialog = true
      },
      editItem(item) {
        this.editedIndex = this.details.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.editedItem.structure_type = this.structureType.find(
          x => x.value === this.editedItem.structure_type
        ).value
        this.dialog = true
      },

      translateType(type) {
        switch (type) {
          case 'NUMBER':
            return i18n.t('structure.types.number')
          case 'LACRE':
            return i18n.t('structure.types.lacre')
          case 'TEXT':
            return i18n.t('structure.types.text')
          case 'LIST':
            return i18n.t('structure.types.list')
          case 'PRODUCT':
            return i18n.t('structure.types.product')
          case 'OPERATOR':
            return i18n.t('structure.types.operator')
          case 'EQUIPMENT':
            return i18n.t('structure.types.equipment')
          case 'SEASON':
            return i18n.t('structure.types.season')
          case 'MEASUREMENTUNIT':
            return i18n.t('structure.types.mu')
          case 'INPUT':
            return i18n.t('structure.types.input')
          case 'LOTE':
            return i18n.t('structure.types.lote')
          case 'DATATIME':
            return i18n.t('structure.types.datetime')
          case 'DATA':
            return i18n.t('structure.types.date')
          case 'TIME':
            return i18n.t('structure.types.time')
          case 'LISTSIMPLE':
            return i18n.t('structure.types.sList')
          case 'PRODUCTSIMPLE':
            return i18n.t('structure.types.sProduct')
          case 'OPERATORSIMPLE':
            return i18n.t('structure.types.sOperator')
          case 'EQUIPMENTSIMPLE':
            return i18n.t('structure.types.sEquipment')
          case 'SEASONSIMPLE':
            return i18n.t('structure.types.sSeason')
          case 'MEASUREMENTUNITSIMPLE':
            return i18n.t('structure.types.smu')
          case 'INPUTSIMPLE':
            return i18n.t('structure.types.sInput')
          case 'QR':
            return i18n.t('structure.types.qr')
        }
      },

      groupDeleteItem() {
        this.groupDialogClose()
        this.deleteGroupStructure([
          this.groupDeletingItem.id,
          this.$toast,
        ]).finally(() => {
          this.fetchGroupStructuresByCompany([this.profile, this.$toast])
        })
      },
      deleteItem() {
        this.dialogClose()
        this.deleteStructure([this.deletingItem.id, this.$toast]).finally(
          () => {
            this.fetchListStructures([this.profile, this.$toast])
          }
        )
      },
      openCreateGroupDialog() {
        this.groupDialog = true
        setTimeout(() => {
          this.$refs.groupForm.reset()
        }, 50)
      },
      openCreateDialog() {
        this.dialog = true
        setTimeout(() => {
          this.$refs.form.reset()
        }, 50)
      },
      groupClose() {
        this.$refs.groupForm.reset()
        this.groupEditedItem = Object.assign({}, this.groupDefaultItem)
        this.groupEditedIndex = -1
        setTimeout(() => {
          this.groupDialog = false
        }, 300)
      },
      close() {
        this.$refs.form.reset()
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        setTimeout(() => {
          this.dialog = false
        }, 300)
      },
      groupSave() {
        let item = Object.assign({}, this.groupEditedItem)
        // Translate
        item.languageKey = LanguajeService.setKey3(
          item.group_details_name,
          item.languageKey,
          !item.id ? this.languageStructure : null
        )
        // *****
        item.company = { id: this.profile.company_id }
        this.saveGroupStructures([
          item,
          this.groupEditedIndex,
          this.$toast,
        ]).finally(() => {
          this.fetchGroupStructuresByCompany([this.profile, this.$toast])
        })
        this.groupClose()
      },
      save() {
        let item = Object.assign({}, this.editedItem)
        // Translate
        item.language_key = LanguajeService.setKey3(
          item.details_name,
          item.language_key,
          !item.id ? this.languageStructure : null
        )
        // *****
        item.structure_component = '-'
        item.company = { id: this.profile.company_id }
        this.saveStructures([item, this.editedIndex, this.$toast]).finally(
          () => {
            this.fetchListStructures([this.profile, this.$toast])
          }
        )
        this.close()
      },
      groupDialogShow(params) {
        this.title = params.title
        this.dialogText = params.dialogText
        this.groupShowDialog = true
      },
      dialogShow(params) {
        this.title = params.title
        this.dialogText = params.dialogText
        this.showDialog = true
      },
      groupDialogClose() {
        this.groupShowDialog = false
      },
      dialogClose() {
        this.showDialog = false
      },
      groupDeleteItemDialog(item) {
        this.groupDeletingItem = item
        this.groupDialogShow({
          title: i18n.t('common.titles.alert'),
          dialogText: i18n.t('common.notification.deleteItems'),
        })
      },
      deleteItemDialog(item) {
        this.deletingItem = item
        this.dialogShow({
          title: i18n.t('common.titles.alert'),
          dialogText: i18n.t('common.notification.deleteItems'),
        })
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
      replaceSearch(event) {
        this.search = event.currentTarget.value.toString().replaceAll('"', '')
      },
      replaceSearch_(event) {
        this.search_ = event.currentTarget.value.toString().replaceAll('"', '')
      },
      replaceDetailsName(event) {
        this.editedItem.details_name = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
      replaceGroupDetailsName(event) {
        this.groupEditedItem.group_details_name = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
    },
  }
</script>

<style scoped></style>
